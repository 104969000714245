<template>
    <div class="wrapper flex flex-column">
        <Header :search-style="{width:0}" :logo="logoUrl">
        </Header>
        <div class="flex-item">
            <div class="organization-content container flex mt20">
                <div class="live-list flex-item">
                    <a-spin :spinning="loading" tip="Loading...">
                        <div class="mh">
                            <!-- 视频推荐模块 -->
                            <div class="flex mb40 live-banner">
                                <a-carousel class="carousel" arrows autoplay :autoplay-speed="4000">
                                    <!--autoplay-->
                                    <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow" style="left: 10px;zIndex: 1">
                                        <a-icon type="left-circle" />
                                    </div>
                                    <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                                        <a-icon type="right-circle" />
                                    </div>
                                    <img :src="item.pic_url | urlFilter(360)" alt="" v-for="(item, index) in bannerList" @touchstart="handleBanerToPage(item)" @click="handleBanerToPage(item)" :key="index">
                                </a-carousel>
                            </div>
                            <common-title title="最新关注" :isMore="false"></common-title>
                            <mescroll-vue :style="isHiddenMescroll ? 'overflow:hidden;' : ''" id="mescroll" ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
                                <div class="meeting-report pr10">
                                    <div v-for="(item, index) in infoListData" :key="index" class="hot-topic-box mt20">
                                        <!-- 资讯 -->
                                        <div v-if="item.show_type == 'news'" class="flex">
                                            <div class="tags news-tag mr10" :style="item.news_pic ? '' : 'position: static;'">资讯</div>
                                            <hot-topic-item path="/infomation-detail" containerWidth :type="item.type" :item="item">
                                            </hot-topic-item>
                                        </div>
                                        <!-- live -->
                                        <div v-if="item.show_type == 'live'" class="flex">
                                            <div class="tags meeting-tag mr10">直播</div>
                                            <live-item type="horizontal" :key="index" :item="item"></live-item>
                                        </div>
                                        <!-- meeting -->
                                        <div class="flex" v-if="item.show_type == 'meeting'" @click="toMeeting(item.href)">
                                            <div class="tags meeting-tag mr10">会议</div>
                                            <across-media-item class="flex-item" type="layoutRight" :key="index" :item="{ img: item.pic_url, title: item.cn_title ? item.cn_title : item.en_title }">
                                                <template v-slot:bottom>
                                                    <div>
                                                        <div class="row-1" v-if="item.start_time">
                                                            <i class="med med-31shijian"></i>
                                                            <span class="color999 fs14 ml5">{{ item.start_time.slice(0, 10) }}</span>
                                                        </div>
                                                        <div class="row-1" v-if="item.venue_name_cn">
                                                            <i class="med med--didian"></i>
                                                            <span class="color999 fs14 ml5">{{ item.venue_name_cn }}</span>
                                                        </div>
                                                    </div>
                                                </template>
                                            </across-media-item>
                                        </div>
                                        <!-- meeting_video -->
                                        <div v-if="item.show_type == 'meeting_video' && item.video.length > 0">
                                            <div class="meeting-video-title mb10">
                                                <span>视频回放</span>
                                                {{item.cn_title ? item.cn_title : item.en_title}}
                                            </div>
                                            <div>
                                                <course-item width="230px" height="130px" :data-list="item.video"></course-item>
                                            </div>
                                        </div>
                                    </div>
                                    <a-empty v-if="!infoListData.length && !loading" />
                                </div>
                            </mescroll-vue>
                        </div>
                    </a-spin>
                </div>
                <div class="live-right">
                    <div class="ranking">
                         <common-title title="机构排行榜" path="/organization/index"></common-title>
                         <div class="ranking-content">
                            <a v-for="(item, index) in rankingList" :key="index" @click="orgJumpPage(item.id)">
                                <div class="flex pr10 font16 pb10 pt10 ranking-item">
                                    <div>
                                        <span class="index">{{index + 1}}</span>
                                        {{item.name}}
                                    </div>
                                    <div>{{item.fans_count}}</div>
                                </div>
                            </a>
                         </div>
                    </div>
                    <div>
                        <layout-right :config="layoutRightConfig"></layout-right>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import hotTopicItem from '@/components/hot-topic-item.vue';
import commonTitle from '@/components/common-title';
import Header from '@/components/layout/layout-header';
import layoutRight from '@/components/layout/layout-right';
import Footer from '@/components/layout/layout-footer';
import courseItem from '@/components/courser-list-item';
import liveItem from '@/components/live-item.vue';
import meetingItem from '@/components/meeting-item.vue';
import { organizationLogo } from '@/config/const'
import { videoJumpDetail } from '@/utils/jumpPageMethods';
import mescrollMixin from '@/utils/mescroll.mixin';
import storage from 'store';
import { mapState } from 'vuex';
import { isArray } from '@/utils/judgeDataType.js'
import { debounce } from '@/utils/common'
import acrossMediaItem from '@/components/across-media-item.vue'
export default {
    mixins: [mescrollMixin],
    name: "OrganizationHome",
    components: {
        commonTitle,
        Header,
        layoutRight,
        Footer,
        hotTopicItem,
        courseItem,
        liveItem,
        meetingItem,
        acrossMediaItem
    },
    data() {
        return {
            isHiddenMescroll: true, // 是否隐藏mescroll滚动条
            infoListData:[],
            rankingList: [], // 机构号列表
            logoUrl: organizationLogo,
            bigImg: 'https://files.sciconf.cn/medcon/2023/03/20230325/2023032516265293610214857.jpg!wx360', //推荐视频大图
            bigVideoId: '',
            layoutRightConfig: {
                meeting: {
                    show: true, // 是否显示
                    title: '本月会议', // 标题
                    methods: 'get',
                    moreLink: '/meeting-list?year='+(new Date()).getFullYear()+'&month=' + ((new Date()).getMonth() + 1),
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 6,
                        is_now_year_meeting: 1,
                        subject_ids: storage.get('subjectIds') || ''
                    },   // 参数条件
                },
                correlationLives: { //相关直播
                    show: true, // 是否显示
                    methods: 'get',
                    title: '推荐直播', // 标题
                    data: {
                        tj_token: storage.get('tid'),
                        page: 1,
                        limit: 3,
                        subject_ids: '',
                        keyword: ''
                    },   // 参数条件
                },

            },
            loading: true,
            subject_id:'',
            bannerList: []
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        handleNavigateEvent(item) {
            console.log(item)
            this.$router.push({
                path: '/video-list-detai',
                query: {
                    id: item.type_id,
                    type: 1
                }
            })
        },
        handleBanerToPage (item) {
            if (item.domain_alone) {
                window.open(item.domain_alone, '_blank');
            }
        },
        getTopMeetingImg () {
            return this.request.post('getTopMeetingImg')
        },
        orgJumpPage(committee_id) {
            this.request.post('CommitteeDetail', { committee_id: committee_id }).then(res => {
                let orgContent = res.data.committee
                storage.set('orgLogoName',JSON.stringify(orgContent))
                let routeData = this.$router.resolve({
                    path: '/organization/org-homepage',
                    query: {
                        committee_id
                    }
                });
                window.open(routeData.href, '_blank');
            })
        },
        // 获取机构排行
        getCommitteeList () {
            return this.request.post('CommitteeList', { size: 5 })
        },
        videoJumpDetail,
        pageInit() {
            let subject_ids = storage.get('subjectIds') || '';
            let requestArr = [
                this.getCommitteeList(),
                this.getTopMeetingImg()
            ]
            Promise.all(requestArr).then(res => {
                // 机构排行榜
                this.rankingList = res[0].data
                this.loading = false
                // banner
                this.bannerList = res[1].data.data || []
            })
        },
        // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
        upCallback(page, mescroll) {
            let data = {
                page: page.num
            }
            // 联网请求
            this.request.post('getOrgIndex', data).then((res) => {
                let arr = res.data || [];
                // 如果是第一页需手动置空列表
                if (page.num === 1) {
                    this.infoListData = arr;
                } else {
                    // 把请求到的数据添加到列表
                    this.infoListData = this.infoListData.concat(arr)
                }
                // 数据渲染成功后,隐藏下拉刷新的状态
                this.$nextTick(() => {
                    mescroll.endSuccess(arr.length, 0);
                    this.loading = false
                })
            }).catch((e) => {
                // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
                mescroll.endErr()
                this.loading = false
            })
        },
        winScrollHandle (e) {
            if (document.documentElement.scrollTop >=500) {
                this.isHiddenMescroll = false
            } else {
                this.isHiddenMescroll = true
            }
        },
        toMeeting (href) {
            window.open(href, '_blank');
        }
    },
    created() {
        this.pageInit()
        window.addEventListener('scroll', debounce(this.winScrollHandle, 200))
    }
}
</script>

<style lang="less" scoped>
.meeting-video-title {
    text-align: left;
    font-size: 0.42667rem;
    font-weight: bold;
    span {
        color: #253E6D;
        margin-right: 5px;
        position: relative;
        margin-right: 10px;
        &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: 15px;
            background: #CCCCCC;
            right: -8px;
            top: 50%;
            transform: translate(0, -50%);
        }
    }
}
.wrapper {
    min-height: 100vh;
    background: url("~@/assets/images/live-nav-top-bg.png") repeat-x;
    background-size: 1920 auto;
}
.mescroll-totop {
    height: 2rem;
}
.mescroll {
    height: 100vh;
    margin-top: 20px;
}
.carousel {
    width: 750px;
    cursor: pointer;
}
.video-list-title {
    display: block;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
        position: relative;
        padding-right: 10px;
        margin-right: 10px;
        color: #253E6D;
        display: inline-block;
        &:before {
            content: '';
            position: absolute;
            width: 2px;
            height: 15px;
            background: #999999;
            right: -10px;
            top: 50%;
            transform: translate(-2px, -50%);
        }
    }
}
.ranking-item {
    display: flex;
    font-size: 14px;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.ranking-content {
    .ranking-item {
        &:hover {
            color: #1890ff;
        }
    }
    & a:nth-child(1) {
        padding-top: 0;
        .index {
            color: #1E51C9;
        }
    }
    & a:nth-child(2) {
        padding-top: 0;
        .index {
            color: #FFA45D;
        }
    }
    & a:nth-child(3) {
        padding-top: 0;
        .index {
            color: #1EABC9;
        }
    }
}
.filter-wrapper {
    background: #ffffff;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
}

.filter-condition {
    background: #fff;
    overflow: hidden;
    height: 8px;
    &.active {
        height: 50px;
        line-height: 50px;
    }
    /deep/.ant-breadcrumb>span:last-child {
        color: inherit;
    }
}

.organization-content {
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}

.live-list {
    margin-right: 30px;

    .mh {
        min-height: 500px;
    }
}

// 直播推荐
.recommend-first {
    width: 100%;
    .rf-image {
        width: 100%;
        height: 259px;
    }
}


.live-right {
    width: 400px;

    .paper-writing {
        height: 24px;
        display: flex;
        cursor: pointer;
    }

    .apply-teacher {
        width: 100%;
        height: 92px;
    }
}
.hot-topic-box {
    position: relative;
    /deep/.report-list {
        .describe {
            -webkit-line-clamp: 2;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            font-size: 14px;
        }
        .hot-topic-item-title {
            font-weight: bold;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            font-size: 16px;
        }
    }
}
@media screen and (max-width:768px){
    .footer {
        display: none !important;
    }
    .carousel {
        width: 100%;
    }
    .wrapper{
        .mh {
            padding: 0 10px;
        }
         .filter-condition.active{
            margin-top:10px;
            height:40px;
            line-height:40px;
            .container{
                padding:0 10px;
                display: flex;
            }
        }
        .filter-wrapper{
            padding: 2px 0;
            border-radius: 0;
            position: sticky;
            top: 50px;
            background: #f2f4fa;
            z-index: 9;
        }
        .organization-content{
            margin-top: 0px;
            display: flex;
            padding: 0;
            flex-wrap: wrap;
            flex-direction: column-reverse;
            .hot-topic-box {
                /deep/.report-list {
                   .describe {
                        -webkit-line-clamp: 1;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        font-size: 14px;
                   }
                   .hot-topic-item-title {
                        font-weight: bold;
                        -webkit-line-clamp: 1;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        font-size: 16px;
                    }
                }
            }
            .live-list{
                width: 100%;
                .live-banner{
                    display: block;
                    width: calc(100% + 20px);
                    margin-left: -10px;
                    margin-bottom: 20px;
                    .recommend-first{
                        width: 100%;
                        height: auto;
                        .rf-image{
                            height: auto;
                        }
                    }
                }
                .video-list{
                    width: 100%;
                    overflow: hidden;
                }
            }
            .live-right{
                width: 100%;
                margin-top: 30px;
                padding: 0 10px;
            }
        }
    }
}
</style>