<template>
    <router-link v-if="item" :to="{ path: item.url,query:{id:item.id}}" :style="{width:width}" class="live-item">
        <div class="live-item-image">
            <img :style="{width:width,height:height?height:(parseInt(width)/16*9+'px')}" :src="item.img" alt="" />
        </div>
        <div class="pt10 pl10 pr10 pb10 t-l">
            <div class="fs16 color333 strong row-2" style="min-height:50px;">{{item.title}}</div>
            <div v-if="item.date || item.address" class="flex mt10 x-left">
                <div v-if="item.date" class="color999 fs14"><i class="med med-31shijian"></i>{{item.date && item.date.substr(0,10)}}</div>
                <div v-if="item.address" class="color999 fs14 ml20"><i class="med med--didian"></i>{{item.address}}</div>
            </div>
            <slot v-else name="bottom"></slot>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "live-item",
    props: {
        width: {
            type: String,
            default: '245px',
        },
        height: {
            type: [Number, String],
            default: 0,
        },
        item: {
            type: Object,
            default: () => {
                // id:1,
                // img:'',
                // title:',
                // url:'',  
                // date:'',
                // address:'',
            },
        },
    },
    data() {
        return {
        }
    },
    methods: {
      
    },
    mounted() {
       
    }
};
</script>

<style scoped lang="less">
.live-item {
    background: #f9f9f9;
    display: block;
    &-image {
        position: relative;
    }
}
@media screen and (max-width: 550px) {
    .live-item {
        .image-left {
            width: 160px !important;
            height: 90px !important;
        }
    }
}
</style>
